import { Config, ConfigSchema } from "@/config/schema";

let _config: Config | null = null;

export function config(): Config {
  if (_config) {
    return _config;
  }

  const parsedConfig = ConfigSchema.safeParse(
    // jest will set this explicitly
    process.env.NODE_ENV === "test"
      ? TEST_CONFIG
      : {
          SKIP_BUILDING_STATIC_PAGES: process.env.SKIP_BUILDING_STATIC_PAGES,

          NEXT_PUBLIC_BASE_DOMAIN: process.env.NEXT_PUBLIC_BASE_DOMAIN,
          NEXT_PUBLIC_COMMIT_SHA: process.env.NEXT_PUBLIC_COMMIT_SHA,
          NEXT_PUBLIC_ZAPIER_API_BASE_URL:
            process.env.NEXT_PUBLIC_ZAPIER_API_BASE_URL,
          NEXT_PUBLIC_MIXPANEL_PROJECT_ID:
            process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_ID,
          NEXT_PUBLIC_SPLIT_IO_CLIENT_AUTH_KEY:
            process.env.NEXT_PUBLIC_SPLIT_IO_CLIENT_AUTH_KEY,
          NEXT_PUBLIC_DATADOG_APP_ID: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
          NEXT_PUBLIC_DATADOG_CLIENT_TOKEN:
            process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
          NEXT_PUBLIC_SPLIT_LOGGING: process.env.NEXT_PUBLIC_SPLIT_LOGGING,

          HEALTH_CHECK_API_KEY: process.env.HEALTH_CHECK_API_KEY,

          NODE_ENV: process.env.NODE_ENV,
          NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,

          REDIS_URL: process.env.REDIS_URL,

          UPSTASH_URL: process.env.UPSTASH_URL,
          UPSTASH_TOKEN: process.env.UPSTASH_TOKEN,
          UPSTASH_TIMEOUT: process.env.UPSTASH_TIMEOUT,

          LOG_LEVEL: process.env.LOG_LEVEL,
          PRISMA_LOGS: process.env.PRISMA_LOGS?.split(","),

          PORT: process.env.PORT,
          SESSION_SECRET: process.env.SESSION_SECRET,

          DATADOG_API_KEY: process.env.DATADOG_API_KEY,
          NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
          SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,

          SPLIT_IO_NODEJS_AUTH_KEY: process.env.SPLIT_IO_NODEJS_AUTH_KEY,
          SPLIT_ADMIN_API_KEY: process.env.SPLIT_ADMIN_API_KEY,

          TABLES_SPLIT_IO_NODEJS_AUTH_KEY:
            process.env.TABLES_SPLIT_IO_NODEJS_AUTH_KEY,

          NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY:
            process.env.NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY,
          NEXT_PUBLIC_INTERFACES_CDN_BASE_URL:
            process.env.NEXT_PUBLIC_INTERFACES_CDN_BASE_URL,
          UPLOADCARE_SECRET: process.env.UPLOADCARE_SECRET,
          UPLOADCARE_METADATA_SECRET: process.env.UPLOADCARE_METADATA_SECRET,
          UPLOADCARE_LOCALHOST_WEBHOOKS_ENABLED:
            process.env.UPLOADCARE_LOCALHOST_WEBHOOKS_ENABLED,
          UPLOADCARE_STORAGE_NAME: process.env.UPLOADCARE_STORAGE_NAME,
          UPLOADCARE_WEBHOOK_SECRET: process.env.UPLOADCARE_WEBHOOK_SECRET,

          STRIPE_API_KEY: process.env.STRIPE_API_KEY,
          STRIPE_CONNECT_ACCOUNT_UPDATED_WEBHOOK_KEY:
            process.env.STRIPE_CONNECT_ACCOUNT_UPDATED_WEBHOOK_KEY,
          STRIPE_CONNECT_ACCOUNT_APPLICATION_DEAUTHORIZED_WEBHOOK_KEY:
            process.env
              .STRIPE_CONNECT_ACCOUNT_APPLICATION_DEAUTHORIZED_WEBHOOK_KEY,
          STRIPE_CONNECT_TAX_SETTINGS_UPDATED_WEBHOOK_KEY:
            process.env.STRIPE_CONNECT_TAX_SETTINGS_UPDATED_WEBHOOK_KEY,
          NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY:
            process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,

          AWS_INTERFACES_UPLOADS_BUCKET:
            process.env.AWS_INTERFACES_UPLOADS_BUCKET,
          AWS_UPLOADCARE_DELETION_ARN: process.env.AWS_UPLOADCARE_DELETION_ARN,
          AWS_DEFAULT_REGION: process.env.AWS_DEFAULT_REGION,

          INTERFACES_CLI_APP_CHECKSUM: process.env.INTERFACES_CLI_APP_CHECKSUM,
          INTERFACES_CLI_APP_ID: process.env.INTERFACES_CLI_APP_ID,

          ZAPIER_API_BASE_URL: process.env.ZAPIER_API_BASE_URL,
          ZAPIER_TABLES_API_BASE_URL: process.env.ZAPIER_TABLES_API_BASE_URL,
          NEXT_PUBLIC_ZAPIER_TABLES_UI_BASE_URL:
            process.env.NEXT_PUBLIC_ZAPIER_TABLES_UI_BASE_URL,

          NEXT_PUBLIC_ZSL_API_BASE_URL:
            process.env.NEXT_PUBLIC_ZSL_API_BASE_URL,

          ZAPIER_CHATBOTS_API_BASE_URL:
            process.env.ZAPIER_CHATBOTS_API_BASE_URL,
          ZAPIER_CHATBOTS_INTERNAL_API_KEY:
            process.env.ZAPIER_CHATBOTS_INTERNAL_API_KEY,

          NEXT_PUBLIC_ZAPIER_CHATBOTS_URL:
            process.env.NEXT_PUBLIC_ZAPIER_CHATBOTS_URL,

          ZAP_CALLBACK_URL: process.env.ZAP_CALLBACK_URL,

          VERCEL: process.env.VERCEL,
          VERCEL_URL: process.env.VERCEL_URL,
          VERCEL_TEAM_ID: process.env.VERCEL_TEAM_ID,
          AUTH_BEARER_TOKEN: process.env.AUTH_BEARER_TOKEN,
          VERCEL_PROJECT_ID: process.env.VERCEL_PROJECT_ID,
          VERCEL_GIT_COMMIT_SHA: process.env.VERCEL_GIT_COMMIT_SHA,

          EMAIL_FROM_NAME: process.env.EMAIL_FROM_NAME,
          EMAIL_FROM_EMAIL: process.env.EMAIL_FROM_EMAIL,
          EMAIL_SMTP_HOST: process.env.EMAIL_SMTP_HOST,
          EMAIL_SMTP_PORT: process.env.EMAIL_SMTP_PORT,
          EMAIL_SMTP_USERNAME: process.env.EMAIL_SMTP_USERNAME,
          EMAIL_SMTP_PASSWORD: process.env.EMAIL_SMTP_PASSWORD,

          NEXT_PUBLIC_INTERFACES_BASE_URL:
            process.env.NEXT_PUBLIC_INTERFACES_BASE_URL,
          ZAPIER_CLIENT_ID: process.env.ZAPIER_CLIENT_ID,
          ZAPIER_CLIENT_SECRET: process.env.ZAPIER_CLIENT_SECRET,
          ZAPIER_ISSUER_BASE_URL: process.env.ZAPIER_ISSUER_BASE_URL,

          JWT_PRIVATE_KEY:
            process.env.JWT_PRIVATE_KEY ?? process.env.IDENTITY_PEM,

          OPENAI_API_KEY: process.env.OPENAI_API_KEY,
          OPENAI_ORG_ID: process.env.OPENAI_ORG_ID,
          OPENAI_MAX_TOKENS: process.env.OPENAI_MAX_TOKENS,

          GOOGLE_CLOUD_API_KEY: process.env.GOOGLE_CLOUD_API_KEY,
          GOOGLE_CLOUD_PROJECT_ID: process.env.GOOGLE_CLOUD_PROJECT_ID,
          NEXT_PUBLIC_VISIBLE_RECAPTCHA_SITE_KEY:
            process.env.NEXT_PUBLIC_VISIBLE_RECAPTCHA_SITE_KEY,
          NEXT_PUBLIC_HIDDEN_RECAPTCHA_SITE_KEY:
            process.env.NEXT_PUBLIC_HIDDEN_RECAPTCHA_SITE_KEY,

          DEFAULT_TABLES_LIMIT: process.env.DEFAULT_TABLES_LIMIT,

          NEXT_PUBLIC_CHAT_LEGAL_TEXT: process.env.NEXT_PUBLIC_CHAT_LEGAL_TEXT,
          NEXT_PUBLIC_CHAT_LEGAL_URL: process.env.NEXT_PUBLIC_CHAT_LEGAL_URL,

          RATE_LIMITING_ENABLED: process.env.RATE_LIMITING_ENABLED,

          RATE_LIMITING_MAX_REQUESTS: process.env.RATE_LIMITING_MAX_REQUESTS,
          RATE_LIMITING_WINDOW_SIZE_MS:
            process.env.RATE_LIMITING_WINDOW_SIZE_MS,

          HOST_RATE_LIMITING_MAX_REQUESTS:
            process.env.HOST_RATE_LIMITING_MAX_REQUESTS,
          HOST_RATE_LIMITING_WINDOW_SIZE_MS:
            process.env.HOST_RATE_LIMITING_WINDOW_SIZE_MS,

          API_RATE_LIMITING_MAX_REQUESTS:
            process.env.API_RATE_LIMITING_MAX_REQUESTS,
          API_RATE_LIMITING_WINDOW_SIZE_MS:
            process.env.API_RATE_LIMITING_WINDOW_SIZE_MS,

          CHAT_RATE_LIMITING_MAX_REQUESTS:
            process.env.CHAT_RATE_LIMITING_MAX_REQUESTS,
          CHAT_RATE_LIMITING_WINDOW_SIZE_MS:
            process.env.CHAT_RATE_LIMITING_WINDOW_SIZE_MS,

          INTERFACES_EDGE_CONFIG: process.env.INTERFACES_EDGE_CONFIG,

          LOCAL_TRPC_LATENCY: process.env.LOCAL_TRPC_LATENCY,

          RODEO_USER_OTP: process.env.RODEO_USER_OTP,

          STYTCH_PROJECT_ID: process.env.STYTCH_PROJECT_ID,
          STYTCH_PUBLIC_TOKEN: process.env.STYTCH_PUBLIC_TOKEN,
          STYTCH_SECRET_TOKEN: process.env.STYTCH_SECRET_TOKEN,
          STYTCH_API_BASE_URL: process.env.STYTCH_API_BASE_URL,
          STYTCH_API_GOOGLE_OAUTH_BASE_URL:
            process.env.STYTCH_API_GOOGLE_OAUTH_BASE_URL,
          STYTCH_MEMBER_CREATED_WEBHOOK_API_KEY:
            process.env.STYTCH_MEMBER_CREATED_WEBHOOK_API_KEY,
        }
  );

  if (!parsedConfig.success) {
    console.error(parsedConfig.error.flatten());

    throw Error("Failed to parse the config");
  }

  _config = parsedConfig.data;

  return _config;
}

export const TEST_CONFIG: Partial<Config> = {
  NEXT_PUBLIC_BASE_DOMAIN: "app.localhost:3999",
  NEXT_PUBLIC_ZAPIER_API_BASE_URL: "http://localhost:8001",

  NODE_ENV: "test",

  LOG_LEVEL: "error",

  PRISMA_LOGS: [],

  PORT: "3999",
  SESSION_SECRET:
    "042b2a98da662604d03ee25e0dd2105ad0c557c3a0208d78ef8dde046dc399de",

  UPLOADCARE_SECRET: "asdf",
  UPLOADCARE_METADATA_SECRET: "123456",
  NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY: "asdf",
  NEXT_PUBLIC_INTERFACES_CDN_BASE_URL:
    "https://interfaces-cdn.zapier-staging.com",

  STRIPE_API_KEY: "asdf",
  STRIPE_CONNECT_ACCOUNT_UPDATED_WEBHOOK_KEY: "asdf",
  STRIPE_CONNECT_ACCOUNT_APPLICATION_DEAUTHORIZED_WEBHOOK_KEY: "asdf",
  STRIPE_CONNECT_TAX_SETTINGS_UPDATED_WEBHOOK_KEY: "asdf",
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: "asdf",

  AWS_INTERFACES_UPLOADS_BUCKET: "asdf",
  AWS_UPLOADCARE_DELETION_ARN: "asdf",
  AWS_DEFAULT_REGION: "asdf",

  HEALTH_CHECK_API_KEY:
    "5f6b5a56986dcb15879ab9733f865dc2a41fcc810c8c14954f7b7c89ab7340bb",

  REDIS_URL: "redis://localhost:6379/0",

  INTERFACES_CLI_APP_CHECKSUM: "asdf",
  INTERFACES_CLI_APP_ID: "asdf",

  ZAPIER_API_BASE_URL: "http://localhost:8001",
  ZAPIER_TABLES_API_BASE_URL: "http://localhost:8000",
  NEXT_PUBLIC_ZAPIER_TABLES_UI_BASE_URL: "http://localhost:8000",

  NEXT_PUBLIC_ZSL_API_BASE_URL: "http://zsl-api.zapier-staging.com",

  ZAPIER_CHATBOTS_API_BASE_URL: "http://localhost:8001/api/chatbots",
  NEXT_PUBLIC_ZAPIER_CHATBOTS_URL: "http://localhost:8001/app/chatbots",

  NEXT_PUBLIC_INTERFACES_BASE_URL: "http://localhost:3999",
  ZAPIER_CLIENT_ID: "asdf",
  ZAPIER_CLIENT_SECRET: "asdf",
  ZAPIER_ISSUER_BASE_URL: "http://localhost",

  OPENAI_API_KEY: "sk-asdf",
  OPENAI_MAX_TOKENS: 2048,
  OPENAI_ORG_ID: "org-asdf",

  DEFAULT_TABLES_LIMIT: 10,

  NEXT_PUBLIC_CHAT_LEGAL_TEXT:
    "All responses are generated by an automated chatbot.",
  NEXT_PUBLIC_CHAT_LEGAL_URL: "https://zapier.com/legal/terms-of-service",

  INTERFACES_EDGE_CONFIG: "http://localhost:8003",

  LOCAL_TRPC_LATENCY: 0,

  JWT_PRIVATE_KEY: `\
-----BEGIN PRIVATE KEY-----
MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC/8z+2KJppiFoR
61lHVVIuNpa3OLHuT3xKtnF8VenBqkJRiex/Or2bX7yQT6KT4cOc2BAdNpYHIySL
kg/kELaiQtKhfaVzGQv+RTBuXBc/Amew+keREEKNvXRXJEPIth7WA0TAVSWwt6ob
QHDyY5kbh5Wnql251y8iZeM/Pb1F5Usy8nBe8QADHWWjH7+wTiybr2vLgKmhP2UY
RO6/g2sBgB4gKyzyxaWdKUdZotOMyW7DmrmzaJgRBA1l99wnj12+6F5i00F4CYQi
UaRHYDSQbkwEr5tpxlh+CqN7rzzjEu/jRUHF+SMO3fgcteW/7NgufYijnU4mm9tg
Iq9/g75zAgMBAAECggEBAJBtF3AZJu3m4VaATaGlqfsJqedcakzUU8HneoZCImtM
jy/I5kBo00d4BmpS8RHyGfwGekFw0/u0zoeRtBE3HiQCwXcvEfEDVInSR3mSIw+O
4LRB7N1SbbE2brYunC4GEjeXKADLUFuiJgTxPMq0c/xJzSnlvzXYmid4oD7yVlqi
tt3Yr8PvZkcKxVBi2HS+NWaPtxq9fhJGquW6skH6y8Zj0HiyknJ5UnXeuLMc/sH0
LW2KqRu3sfwhfGslPeY20BNQahqwh2eD1amHAsDpfkl63lx7dwCsaOP3lmLNN22J
t/XHVePmjYwEoTHQTN2ER/ZSCySL6PcR+pFnxTovqGkCgYEA+95z4EXJtRQLflQj
2AIxDqSHsbNQ59oLAN2H9upJJUHiyTuc0jfxthdusF12Lx+GZPpsF7k3A+8Arogc
cxkRDurtzxORX+A/3iMnHHqm/iAfJZdeMwrRNAl/i5mQp/z/2om9BI+nLIEXgCft
jD8AtFCt0wrZN/JcorwXcpoQuzcCgYEAwxk1lmHJluo52cCq4aem6lOPDPmcSCg7
DnqN/r/ugNJySLny6e2DmiLZkJMJcxOIGWtG8k8qJAXAz+tkp1ejc66fgidYwTE6
QysxLJcGr1mMdTOdbMXLbj7u2iISJuLW5oZQGCYIMbawWf0CNgPjfQ8lg0Gqz8lB
O3Uy6dzvjKUCgYBiVbiiAD0/QGM4YDtaU4i5scz0uRDGZJ/VJ6i040XJkQ6tCGQy
wvrQ4BcsLmE9Bq7e79KR9JMhKwf2HFgXK9s4GsrcBUTFzPTCkrDrcouOPhZLA+0Y
OonxKiAs7bjHjYSK2yn8BOX8/y2gsHYTQmnQ/IrREbQ7HpnCQ3NYIASWQwKBgBuU
x8efwXENYepP25iVK/q6n2GVNCvbTCYK22IlXiFmHvP7HcVGZ7F1tc5DizVykG4P
hXMiD/IRwiViwNMLh7cF+9TZnSmKvRLb21LTcwV9Imc7Sckj2VOXoArEgE5xCTKD
0HTpg4ULr7i5FQPv3sxCROpecTh42jj+o5j+O/tlAoGANwP0ZJEZunUtDZOyX3SL
cSuGLwSSUPWSF3d5PJRG8tHA/NVreziZfjnj25MCuCxKfrJpuj826Q+hGeSaIi+c
IE2rnbLrxH3Zm3jL4wPl8Iu43pSIY/ndf4c0Mq8koYVmvtzg0wt0sRnNKXqynR6I
KaKhx2u4tBeYu3MTSTeyZ2M=
-----END PRIVATE KEY-----`,

  RODEO_USER_OTP: "123456",

  STYTCH_PROJECT_ID: "project-test-7902b59b-c4ad-4103-8010-b2c330d337a4",
  STYTCH_PUBLIC_TOKEN: "public-token-test-ef0c5069-65fd-426e-bd03-2708877316e1",
  STYTCH_SECRET_TOKEN: "123",
  STYTCH_API_BASE_URL: "https://test.stytch.com",
  STYTCH_API_GOOGLE_OAUTH_BASE_URL: "https://test.stytch.com",
  STYTCH_MEMBER_CREATED_WEBHOOK_API_KEY: "123",
};

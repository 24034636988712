// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = "/api/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"97427062b23fccc2a103fadc9f892fbcdb7bb9ad"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_APP_ENV,
  release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  debug: false,
  integrations: [Sentry.extraErrorDataIntegration({ captureErrorCause: true })],
});
